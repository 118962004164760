import { Api, CheckCustomerForm, EncryptedTokenAssertionForm } from '@api/storeFrontApi';

const api = new Api({ baseUrl: '' });

export const checkExistingCustomer = (form: CheckCustomerForm) => api.login.checkCustomerExistsUsingPost(form);

export const checkExistingB2bCustomer = (organisationNumber: string) =>
  api.login.checkExistingB2BCustomerUsingGet({ organisationNumber });

export const checkB2bCustomerBySapId = (sapid: string) => api.login.checkExistingB2BCustomerBySapIdUsingGet({ sapid });

export const verifySmsToken = (form: EncryptedTokenAssertionForm) => api.login.verifySmsTokenUsingPost(form);

export const validateEmailToken = (token: string) =>
  api.login.validateEmailTokenUsingPost({ token }, { format: 'json' });

export const changePassword = (
  token?: string,
  pwd?: string,
  checkPwd?: string,
  rememberMe?: boolean,
  pwdKey?: string,
  checkPwdKey?: string
) =>
  api.login.changePasswordUsingPost({
    token,
    pwd,
    checkPwd,
    rememberMe,
    pwdKey,
    checkPwdKey,
  });
